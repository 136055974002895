import React from "react";
import Time from "../time.js";

const PostDate = (props) => {
  const domNode = props.domNode;
  if (props.date) {
    return (<Time date={props.date} className={ domNode.attribs.class } />);
  }
  return (<></>);
};

export default PostDate;
